@import 'mixins';
/*COLORS -------------------------------------------------------------------------------------------------------------*/
$color-main: #ffffff;
//focus:
$color-focus: #9dbdda;
//shadows:
$color-shadow: #000;
//background bottom menu:
$color-bg: rgba(255, 255, 255, 0.35);
$secondary-bg: #8a9bab;
//switches:
$switch-active: #3ae1ab;
$switch-inactive: #3f7785;
//texts:
$primary-text: #ffffff;
$secondary-text: #e6e6e6;
$third-text: #4a4a4a;

/*BACKGROUNDS --------------------------------------------------------------------------------------------------------*/
$first-bg: 'background.jpg';
$second-bg: 'background-default.jpg';
$third-bg: 'background-turk.png';

/*FONTS --------------------------------------------------------------------------------------------------------------*/
$font-family: 'Quicksand';
$font-family-turk: 'Raleway';
$font-family-tekno: 'Rubik';

@mixin font-face() {
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/Quicksand-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    src: url('./../fonts/Quicksand-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 600;
    src: url('./../fonts/Quicksand-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: $font-family-tekno;
    font-style: normal;
    font-weight: 700;
    src: url('./../fonts/Rubik-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family-tekno;
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/Rubik-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family-tekno;
    font-style: normal;
    font-weight: 400;
    src: url('./../fonts/Rubik-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family-tekno;
    font-style: normal;
    font-weight: 600;
    src: url('./../fonts/Rubik-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: $font-family-tekno;
    font-style: normal;
    font-weight: 700;
    src: url('./../fonts/Rubik-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: $font-family-turk;
    font-style: normal;
    font-weight: 700;
    src: url('./../fonts/Quicksand-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family-turk;
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/Raleway-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family-turk;
    font-style: normal;
    font-weight: 400;
    src: url('./../fonts/Raleway-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: $font-family-turk;
    font-style: normal;
    font-weight: 600;
    src: url('./../fonts/Raleway-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: $font-family-turk;
    font-style: normal;
    font-weight: 700;
    src: url('./../fonts/Raleway-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/OpenSans-Light.ttf') format('truetype');
  }
}

.tekno-theme {
  %font-light {
    font-family: $font-family-tekno;
    font-weight: 300;
  }
  %font-regular {
    font-family: $font-family-tekno;
    font-weight: 400;
  }
  %font-semibold {
    font-family: $font-family-tekno;
    font-weight: 500;
  }
  %font-bold {
    font-family: $font-family-tekno;
    font-weight: 700;
  }
}

.turk-theme {
  %font-light {
    font-family: $font-family-turk;
    font-weight: 300;
  }
  %font-openSans {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }
  %font-regular {
    font-family: $font-family-turk;
    font-weight: 400;
  }
  %font-semibold {
    font-family: $font-family-turk;
    font-weight: 500;
  }
  %font-bold {
    font-family: $font-family-turk;
    font-weight: 700;
  }
}

.default-theme,
.nob-theme {
  %font-light {
    font-family: $font-family;
    font-weight: 300;
  }
  %font-regular {
    font-family: $font-family;
    font-weight: 400;
  }
  %font-semibold {
    font-family: $font-family;
    font-weight: 600;
  }
  %font-bold {
    font-family: $font-family;
    font-weight: 700;
  }
}

/*ANIMATIONS ---------------------------------------------------------------------------------------------------------*/
%shine {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  background-size: 500% 500%;
  @include animation(shine 3s linear infinite);
  @include keyframes(shine) {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
}
