@import './reset';
@import './themes';

@include font-face();

#app {
  width: $app-width;
  height: $app-height;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: contain;
  &.nob-theme {
    background-image: url('./../images/backgrounds/#{$first-bg}');

    .appDetailsView #waveBack {
      background-image: url('../../assets/images/backgrounds/background-store-detail.png');
    }
  }
  &.default-theme {
    background-image: url('./../images/backgrounds/#{$second-bg}');

    #Header #branding {
      #logo {
       // background-image: url('../../assets/images/logo-default.png');
      }
      #tvStore {
        background: none;
      }
    }

    .appDetailsView #waveBack {
      //background-image: url('../../assets/images/backgrounds/background-store-detail-default.png');
    }
  }
  &.turk-theme {
    background-image: url('./../images/backgrounds/#{$third-bg}');

    .tosView {
      color: #1a1a1a;
    }

    #portalFilter p {
      color: #1a1a1a;
    }
    #portalFilter .fa-check {
      color: #140e2a;
    }
    .welcome {
      color: #1a1a1a;
    }
    #Header {
      .headerPart {
        bottom: v(25px);
      }
      #menu {
        .optionMenu {
          background-color: rgba(21, 58, 100, 0.5);
          &.focus {
            box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          }
          .text {
            font-weight: 500;
            font-size: toRem(32);
          }
        }
      }
      #branding {
        #logo {
          background-image: url('../../assets/images/logo-turk.png');
          width: h(308px);
          height: v(100px);
        }
        #tvStore {
          background: none;
        }
      }
    }

    .carousel .wrapper .slide .image {
      border-radius: h(8px);
    }
    .carousel .wrapper .focus .carouselShine {
      border-radius: h(8px);
    }

    .homeView {
      #points {
        .point {
          background-color: rgba(21, 58, 100, 0.35);
          &.selected {
            background-color: #153a64;
          }
        }
      }
      .carouselTitle,
      .fixedTitle,
      .fixedExtended {
        color: #1a1a1a;
      }

      #homeContainer {
        .focus .app .bg .shining {
          border-radius: h(8px);
        }
        .app .bg {
          border-radius: h(8px);
        }
        &.carousel_1 {
          top: h(-445px);
        }
        &.carousel_5 {
          top: h(-2350px);
        }
        #carouselTvCategoriesWrapper {
          .title {
            font-weight: 500;
          }
          .focus .title {
            font-weight: 600;
          }
        }
        #categoriesCarousel {
          .app {
            .title {
              font-size: v(50px);
              font-weight: 500;
            }
          }
          .focus {
            .bg {
              background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
                linear-gradient(119deg, #395196, #5eb7c7 102%);
            }
            .title {
              font-size: v(60px);
              font-weight: 700;
            }
            .title.multiLine {
              font-size: v(50px);

            }
          }
        }
      }
    }
    #search {
      #menuResults div,
      .carousel_recommended_movies .slide_title,
      #searchHistoryTitle,
      .userInput,
      .input .tip,
      .historyElem,
      .keyMatrix .key {
        color: #1a1a1a;
      }
      .number {
        @extend %font-openSans;
      }
      .keyMatrix .fa-backspace {
        visibility: hidden;
      }
      .keyMatrix .backspace {
        background-image: url('../images/keyboard/backspace.png');
        background-repeat: no-repeat;
        background-position: center;
      }
      .keyMatrix.alphabetic .key:last-child .backKey {
        width: v(60px);
      }
      .keyMatrix .space {
        background-image: url('../images/keyboard/space-light-turk.png');
      }
      .keyMatrix .focus > .space {
        background-image: url('../images/keyboard/space-dark-turk.png');
      }
      .historyElem.focus {
        color: #1a1a1a;
        font-weight: 700;
      }
      #menuResults div.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
    }

    .settings {
      #menuSettings .settingsOption.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
      .switch .filling {
        background-color: #75cdd3;
      }
      #portalFilter .fa-check {
        color: #140e2a;
      }
      #languagesFilter p,
      #portalFilter .matrixSlide p,
      #tagLine,
      #settingsHeader,
      #menuSettings .settingsOption,
      #resetPortal .text {
        color: #1a1a1a;
      }
      #resetPortal .button {
        background-color: rgba(21, 58, 100, 0.5);
        &.focus {
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(111deg, #6385e4, #c86dd7);
        }
      }
      #resetPortal .button p,
      #languagesFilter p {
        font-weight: 500;
        font-size: toRem(32);
      }
    }

    .appDetailsView {
      .button {
        background-color: rgba(21, 58, 100, 0.5);
        color: #ffffff;
        .text {
          font-weight: 500;
          font-size: toRem(32);
          top: h(-14px);
          left: v(-2px);
        }
        &.focus {
          color: #1a1a1a;
          background-color: #ffffff;
        }
      }
      #addToMyApps {
        display: none;
      }
      .appLogo {
        border-radius: h(16px);
      }
      #waveBack {
        background-image: url('../../assets/images/backgrounds/background-turk-detail.png');
      }
      .appData,
      .carouselTitle {
        color: #1a1a1a;
      }
      .languages {
        top: v(108px);
      }
      .carouselTitle {
        font-size: toRem(40);
      }
    }
    .appCategory {
      .categoryTitle,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg img {
          border-radius: h(8px);
        }
      }
    }
    .moviesCategory {
      .title,
      .title_extended,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
    .videoCategory {
      .viewCategory,
      .title_extended,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #ffffff;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
  }
  &.tekno-theme {
    //background: linear-gradient(135deg, #3B464D 0%, #162026 100%);
    background-image: url('../../assets/images/backgrounds/bg-xiaomi.jpg');
    .tosView {
      color: #ffffff;
    }

    .welcome {
      color: #ffffff;
    }
    #Header {
      top: v(70.5px);
      .headerPart {
        bottom: v(25px);
      }
      #menu {
        .optionMenu {
          background-color: rgba(21, 58, 100, 0.5);
          &.focus {
            box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          }
          .text {
            font-weight: 500;
            font-size: toRem(32);
          }
        }
      }
      #branding {
        #logo {
         // background-image: url('../../assets/images/logo_artel.png');
          background-position: center;
          width: h(250px);
          height: v(100px);
        }
        #tvStore {
          background: none;
        }
      }
    }

    .carousel .wrapper .slide .image {
      border-radius: h(8px);
    }
    .carousel .wrapper .focus .carouselShine {
      border-radius: h(8px);
    }

    .homeView {
      overflow: hidden;
      width: v(1772px);
      #points {
        .point {
          background-color: rgba(255, 255, 255, 0.35);
          &.selected {
            background-color: #ffffff;
          }
        }
      }
      .carousel_banners {
        margin-left: v(88px);
      }
      .carouselTitle,
      .fixedTitle,
      .fixedExtended {
        color: #ffffff;
      }

      #homeContainer {
        .fixedTitle {
          top: v(280px);
          left: h(275px);
        }
        .focus .app .bg .shining {
          border-radius: h(8px);
        }
        .app .bg {
          border-radius: h(8px);
        }
        &.carousel_1 {
          top: h(-300px);
        }
        &.carousel_2 {
          top: h(-650px);
        }
        &.carousel_3 {
          top: h(-1260px);
        }
        &.carousel_4 {
          top: h(-1660px);
        }
        &.carousel_5 {
          top: h(-2250px);
        }
        #carouselTvCategoriesWrapper {
          .title {
            font-weight: 500;
          }
          .focus .title {
            font-weight: 600;
          }
        }
        #categoriesCarousel {
          .app {
            .title {
              font-size: v(50px);
              font-weight: 500;
            }
          }
          .focus {
            .bg {
              background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
                linear-gradient(119deg, #395196, #5eb7c7 102%);
            }
            .title {
              font-size: v(60px);
              font-weight: 700;
            }
            .title.multiLine {
              font-size: v(50px);

            }
          }
        }
      }
    }
    /*#search {
      #menuResults div,
      .carousel_recommended_movies .slide_title,
      #searchHistoryTitle,
      .userInput,
      .input .tip,
      .historyElem,
      .keyMatrix .key {
        color: #ffffff;
      }
      .number {
        @extend %font-openSans;
      }
      .keyMatrix .fa-backspace {
        visibility: hidden;
      }
      .keyMatrix .backspace {
        background-image: url('../images/keyboard/backspace.png');
        background-repeat: no-repeat;
        background-position: center;
      }
      .keyMatrix.alphabetic .key:last-child .backKey {
        width: v(60px);
      }
      .keyMatrix .space {
        background-image: url('../images/keyboard/space-light-turk.png');
      }
      .keyMatrix .focus > .space {
        background-image: url('../images/keyboard/space-dark-turk.png');
      }
      .historyElem.focus {
        color: #1a1a1a;
        font-weight: 700;
      }
      #menuResults div.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
    }*/

    .appDetailsView {
      .backgroundOverlay {
        background: rgba(4,14,35,0.9);
      }
      #waveBack {
        top: 475px;
        background: rgba(16,33,79,0.7);
      }
    }
    /*.appCategory {
      .categoryTitle,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg img {
          border-radius: h(8px);
        }
      }
    }*/
  }
}

.view {
  display: inline-block;
  vertical-align: top;
  margin-top: v(170px);
  width: h(1740px);
  height: v(6000px);
  position: relative;
  z-index: 1;
}

#screen {
  position: absolute;
  width: $app-width;
  height: $app-height;
  background-position: center;
  background-size: contain;
  z-index: 10;
  &.diunsa {
    background-image: url('../../assets/images/diusa_screen.jpg');
  }
  &.youtube {
    background-image: url('../../assets/images/tekno_youtubedemo.png');
  }
}

.recommendation_diunsa {
  background-image: url('../../assets/images/diunsa.png');
}
