@import '../../assets/styles/themes';
@import '../../assets/styles/sizes';

#popUp {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 7;
}
#popupcover,{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}
#popupcontainer{
  position: absolute;
  left: h(563px);
  top:v(315px);
  width: h(795px);
  height: v(450px);
  background: #12214D;
  border-radius: 30px;

}
#popUpTitle{
  position: absolute;
  top: v(57px);
  width: 100%;
  text-align: center;
  color: white;
  font-size: v(42px);
  @extend %font-bold;
}
#popUpDesc{
  position: absolute;
  top: v(141px);
  left: h(80px);
  text-align: center;
  max-width: h(626px);
  white-space: normal;
  color: white;
  font-size: v(36px);
  @extend %font-regular;
}
#popUpButton{
  position: absolute;
  top: v(312px);
  left: h(292px);
  width: h(210px);
  height: v(81px);
  border-radius: 40px;
  background-color: white;
  color: black;
  line-height: v(79px);
  text-align: center;
  font-size: h(36px);
  @extend %font-regular;

}
