/* Settings */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline; }

p {
  margin-block-start: 0em;
  margin-block-end: 0em; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  font-size: 66%; }

/*COLORS -------------------------------------------------------------------------------------------------------------*/
/*BACKGROUNDS --------------------------------------------------------------------------------------------------------*/
/*FONTS --------------------------------------------------------------------------------------------------------------*/
#app.turk-theme #search .number {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300; }

/*ANIMATIONS ---------------------------------------------------------------------------------------------------------*/
@-webkit-keyframes shine {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

@-moz-keyframes shine {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

@-ms-keyframes shine { }

@-o-keyframes shine {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

@keyframes shine {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  src: url("./../fonts/Quicksand-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/Quicksand-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  src: url("./../fonts/Quicksand-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Rubik-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  src: url("./../fonts/Rubik-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/Rubik-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  src: url("./../fonts/Rubik-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Rubik-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Quicksand-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  src: url("./../fonts/Raleway-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("./../fonts/Raleway-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  src: url("./../fonts/Raleway-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: url("./../fonts/Raleway-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("./../fonts/OpenSans-Light.ttf") format("truetype"); }

#app {
  width: 1280px;
  height: 720px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: contain; }
  #app.nob-theme {
    background-image: url("./../images/backgrounds/background.jpg"); }
    #app.nob-theme .appDetailsView #waveBack {
      background-image: url("../../assets/images/backgrounds/background-store-detail.png"); }
  #app.default-theme {
    background-image: url("./../images/backgrounds/background-default.jpg"); }
    #app.default-theme #Header #branding #tvStore {
      background: none; }
  #app.turk-theme {
    background-image: url("./../images/backgrounds/background-turk.png"); }
    #app.turk-theme .tosView {
      color: #1a1a1a; }
    #app.turk-theme #portalFilter p {
      color: #1a1a1a; }
    #app.turk-theme #portalFilter .fa-check {
      color: #140e2a; }
    #app.turk-theme .welcome {
      color: #1a1a1a; }
    #app.turk-theme #Header .headerPart {
      bottom: 16px; }
    #app.turk-theme #Header #menu .optionMenu {
      background-color: rgba(21, 58, 100, 0.5); }
      #app.turk-theme #Header #menu .optionMenu.focus {
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5); }
      #app.turk-theme #Header #menu .optionMenu .text {
        font-weight: 500;
        font-size: 2rem; }
    #app.turk-theme #Header #branding #logo {
      background-image: url("../../assets/images/logo-turk.png");
      width: 205px;
      height: 66px; }
    #app.turk-theme #Header #branding #tvStore {
      background: none; }
    #app.turk-theme .carousel .wrapper .slide .image {
      border-radius: 5px; }
    #app.turk-theme .carousel .wrapper .focus .carouselShine {
      border-radius: 5px; }
    #app.turk-theme .homeView #points .point {
      background-color: rgba(21, 58, 100, 0.35); }
      #app.turk-theme .homeView #points .point.selected {
        background-color: #153a64; }
    #app.turk-theme .homeView .carouselTitle,
    #app.turk-theme .homeView .fixedTitle,
    #app.turk-theme .homeView .fixedExtended {
      color: #1a1a1a; }
    #app.turk-theme .homeView #homeContainer .focus .app .bg .shining {
      border-radius: 5px; }
    #app.turk-theme .homeView #homeContainer .app .bg {
      border-radius: 5px; }
    #app.turk-theme .homeView #homeContainer.carousel_1 {
      top: -297px; }
    #app.turk-theme .homeView #homeContainer.carousel_5 {
      top: -1567px; }
    #app.turk-theme .homeView #homeContainer #carouselTvCategoriesWrapper .title {
      font-weight: 500; }
    #app.turk-theme .homeView #homeContainer #carouselTvCategoriesWrapper .focus .title {
      font-weight: 600; }
    #app.turk-theme .homeView #homeContainer #categoriesCarousel .app .title {
      font-size: 33px;
      font-weight: 500; }
    #app.turk-theme .homeView #homeContainer #categoriesCarousel .focus .bg {
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), linear-gradient(119deg, #395196, #5eb7c7 102%); }
    #app.turk-theme .homeView #homeContainer #categoriesCarousel .focus .title {
      font-size: 40px;
      font-weight: 700; }
    #app.turk-theme .homeView #homeContainer #categoriesCarousel .focus .title.multiLine {
      font-size: 33px; }
    #app.turk-theme #search #menuResults div,
    #app.turk-theme #search .carousel_recommended_movies .slide_title,
    #app.turk-theme #search #searchHistoryTitle,
    #app.turk-theme #search .userInput,
    #app.turk-theme #search .input .tip,
    #app.turk-theme #search .historyElem,
    #app.turk-theme #search .keyMatrix .key {
      color: #1a1a1a; }
    #app.turk-theme #search .keyMatrix .fa-backspace {
      visibility: hidden; }
    #app.turk-theme #search .keyMatrix .backspace {
      background-image: url("../images/keyboard/backspace.png");
      background-repeat: no-repeat;
      background-position: center; }
    #app.turk-theme #search .keyMatrix.alphabetic .key:last-child .backKey {
      width: 40px; }
    #app.turk-theme #search .keyMatrix .space {
      background-image: url("../images/keyboard/space-light-turk.png"); }
    #app.turk-theme #search .keyMatrix .focus > .space {
      background-image: url("../images/keyboard/space-dark-turk.png"); }
    #app.turk-theme #search .historyElem.focus {
      color: #1a1a1a;
      font-weight: 700; }
    #app.turk-theme #search #menuResults div.active {
      background-color: rgba(21, 58, 100, 0.25); }
    #app.turk-theme .settings #menuSettings .settingsOption.active {
      background-color: rgba(21, 58, 100, 0.25); }
    #app.turk-theme .settings .switch .filling {
      background-color: #75cdd3; }
    #app.turk-theme .settings #portalFilter .fa-check {
      color: #140e2a; }
    #app.turk-theme .settings #languagesFilter p,
    #app.turk-theme .settings #portalFilter .matrixSlide p,
    #app.turk-theme .settings #tagLine,
    #app.turk-theme .settings #settingsHeader,
    #app.turk-theme .settings #menuSettings .settingsOption,
    #app.turk-theme .settings #resetPortal .text {
      color: #1a1a1a; }
    #app.turk-theme .settings #resetPortal .button {
      background-color: rgba(21, 58, 100, 0.5); }
      #app.turk-theme .settings #resetPortal .button.focus {
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
        background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(111deg, #6385e4, #c86dd7); }
    #app.turk-theme .settings #resetPortal .button p,
    #app.turk-theme .settings #languagesFilter p {
      font-weight: 500;
      font-size: 2rem; }
    #app.turk-theme .appDetailsView .button {
      background-color: rgba(21, 58, 100, 0.5);
      color: #ffffff; }
      #app.turk-theme .appDetailsView .button .text {
        font-weight: 500;
        font-size: 2rem;
        top: -10px;
        left: -2px; }
      #app.turk-theme .appDetailsView .button.focus {
        color: #1a1a1a;
        background-color: #ffffff; }
    #app.turk-theme .appDetailsView #addToMyApps {
      display: none; }
    #app.turk-theme .appDetailsView .appLogo {
      border-radius: 10px; }
    #app.turk-theme .appDetailsView #waveBack {
      background-image: url("../../assets/images/backgrounds/background-turk-detail.png"); }
    #app.turk-theme .appDetailsView .appData,
    #app.turk-theme .appDetailsView .carouselTitle {
      color: #1a1a1a; }
    #app.turk-theme .appDetailsView .languages {
      top: 72px; }
    #app.turk-theme .appDetailsView .carouselTitle {
      font-size: 2.5rem; }
    #app.turk-theme .appCategory .categoryTitle,
    #app.turk-theme .appCategory .viewTitle {
      color: #1a1a1a; }
    #app.turk-theme .appCategory .app .title {
      color: #1a1a1a; }
    #app.turk-theme .appCategory .app .bg img {
      border-radius: 5px; }
    #app.turk-theme .moviesCategory .title,
    #app.turk-theme .moviesCategory .title_extended,
    #app.turk-theme .moviesCategory .viewTitle {
      color: #1a1a1a; }
    #app.turk-theme .moviesCategory .app .title {
      color: #1a1a1a; }
    #app.turk-theme .moviesCategory .app .bg .image {
      border-radius: 5px; }
    #app.turk-theme .moviesCategory .focus .app .bg .shining {
      border-radius: 5px; }
    #app.turk-theme .videoCategory .viewCategory,
    #app.turk-theme .videoCategory .title_extended,
    #app.turk-theme .videoCategory .viewTitle {
      color: #1a1a1a; }
    #app.turk-theme .videoCategory .app .title {
      color: #ffffff; }
    #app.turk-theme .videoCategory .app .bg .image {
      border-radius: 5px; }
    #app.turk-theme .videoCategory .focus .app .bg .shining {
      border-radius: 5px; }
  #app.tekno-theme {
    background-image: url("../../assets/images/backgrounds/bg-xiaomi.jpg");
    /*#search {
      #menuResults div,
      .carousel_recommended_movies .slide_title,
      #searchHistoryTitle,
      .userInput,
      .input .tip,
      .historyElem,
      .keyMatrix .key {
        color: #ffffff;
      }
      .number {
        @extend %font-openSans;
      }
      .keyMatrix .fa-backspace {
        visibility: hidden;
      }
      .keyMatrix .backspace {
        background-image: url('../images/keyboard/backspace.png');
        background-repeat: no-repeat;
        background-position: center;
      }
      .keyMatrix.alphabetic .key:last-child .backKey {
        width: v(60px);
      }
      .keyMatrix .space {
        background-image: url('../images/keyboard/space-light-turk.png');
      }
      .keyMatrix .focus > .space {
        background-image: url('../images/keyboard/space-dark-turk.png');
      }
      .historyElem.focus {
        color: #1a1a1a;
        font-weight: 700;
      }
      #menuResults div.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
    }*/
    /*.appCategory {
      .categoryTitle,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg img {
          border-radius: h(8px);
        }
      }
    }*/ }
    #app.tekno-theme .tosView {
      color: #ffffff; }
    #app.tekno-theme .welcome {
      color: #ffffff; }
    #app.tekno-theme #Header {
      top: 47px; }
      #app.tekno-theme #Header .headerPart {
        bottom: 16px; }
      #app.tekno-theme #Header #menu .optionMenu {
        background-color: rgba(21, 58, 100, 0.5); }
        #app.tekno-theme #Header #menu .optionMenu.focus {
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5); }
        #app.tekno-theme #Header #menu .optionMenu .text {
          font-weight: 500;
          font-size: 2rem; }
      #app.tekno-theme #Header #branding #logo {
        background-position: center;
        width: 166px;
        height: 66px; }
      #app.tekno-theme #Header #branding #tvStore {
        background: none; }
    #app.tekno-theme .carousel .wrapper .slide .image {
      border-radius: 5px; }
    #app.tekno-theme .carousel .wrapper .focus .carouselShine {
      border-radius: 5px; }
    #app.tekno-theme .homeView {
      overflow: hidden;
      width: 1181px; }
      #app.tekno-theme .homeView #points .point {
        background-color: rgba(255, 255, 255, 0.35); }
        #app.tekno-theme .homeView #points .point.selected {
          background-color: #ffffff; }
      #app.tekno-theme .homeView .carousel_banners {
        margin-left: 58px; }
      #app.tekno-theme .homeView .carouselTitle,
      #app.tekno-theme .homeView .fixedTitle,
      #app.tekno-theme .homeView .fixedExtended {
        color: #ffffff; }
      #app.tekno-theme .homeView #homeContainer .fixedTitle {
        top: 186px;
        left: 183px; }
      #app.tekno-theme .homeView #homeContainer .focus .app .bg .shining {
        border-radius: 5px; }
      #app.tekno-theme .homeView #homeContainer .app .bg {
        border-radius: 5px; }
      #app.tekno-theme .homeView #homeContainer.carousel_1 {
        top: -200px; }
      #app.tekno-theme .homeView #homeContainer.carousel_2 {
        top: -434px; }
      #app.tekno-theme .homeView #homeContainer.carousel_3 {
        top: -840px; }
      #app.tekno-theme .homeView #homeContainer.carousel_4 {
        top: -1107px; }
      #app.tekno-theme .homeView #homeContainer.carousel_5 {
        top: -1500px; }
      #app.tekno-theme .homeView #homeContainer #carouselTvCategoriesWrapper .title {
        font-weight: 500; }
      #app.tekno-theme .homeView #homeContainer #carouselTvCategoriesWrapper .focus .title {
        font-weight: 600; }
      #app.tekno-theme .homeView #homeContainer #categoriesCarousel .app .title {
        font-size: 33px;
        font-weight: 500; }
      #app.tekno-theme .homeView #homeContainer #categoriesCarousel .focus .bg {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), linear-gradient(119deg, #395196, #5eb7c7 102%); }
      #app.tekno-theme .homeView #homeContainer #categoriesCarousel .focus .title {
        font-size: 40px;
        font-weight: 700; }
      #app.tekno-theme .homeView #homeContainer #categoriesCarousel .focus .title.multiLine {
        font-size: 33px; }
    #app.tekno-theme .appDetailsView .backgroundOverlay {
      background: rgba(4, 14, 35, 0.9); }
    #app.tekno-theme .appDetailsView #waveBack {
      top: 475px;
      background: rgba(16, 33, 79, 0.7); }

.view {
  display: inline-block;
  vertical-align: top;
  margin-top: 113px;
  width: 1160px;
  height: 4000px;
  position: relative;
  z-index: 1; }

#screen {
  position: absolute;
  width: 1280px;
  height: 720px;
  background-position: center;
  background-size: contain;
  z-index: 10; }
  #screen.diunsa {
    background-image: url("../../assets/images/diusa_screen.jpg"); }
  #screen.youtube {
    background-image: url("../../assets/images/tekno_youtubedemo.png"); }

.recommendation_diunsa {
  background-image: url("../../assets/images/diunsa.png"); }
